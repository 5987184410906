import ApiClient from 'services/ApiClient';

const format = '?format=json';

export const ApiGetData = {
  getData(indicator, params) {
    const url = `${indicator}${format}`;
    return ApiClient.get(url, { params });
  },
};
