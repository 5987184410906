import React from 'react';
import './TopBar.css';
import constants from './constants';
import logoIser from 'assets/logos/iser_labs_32_White.png';
import flag from 'assets/colombiaFlag.jpg';

const TopBar = () => (
  <header className='top_bar'>
    <span className='top_bar_title'>
      <img src={flag} alt='' className='flag' />
      {constants.title}
    </span>

    <span className='small-text'>Presentado por</span>

    <span className='top_bar_brand'>
      <div className='company'>
        <img src={logoIser} alt='trust' className='logo' />
        <a href={constants.iserURL} target='_blank' rel='noreferrer' className='link'>
          {constants.iser}
        </a>
      </div>
    </span>
  </header>
);

export default TopBar;
