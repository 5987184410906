import React from 'react';
import './App.css';
import TopBar from 'components/TopBar';
import Dashboard from 'components/Dashboard';

function App() {
  return (
    <div className="App">
      <TopBar />    
      <Dashboard />
    </div>
  );
}

export default App;
