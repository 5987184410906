import { useState, useEffect } from 'react';
import './Dashboard.css';
import config from 'config';
import { ApiGetData } from 'services/ApiGetData';
import SectionCharts from './SectionCharts';

const Section = ({ title, data }) => {
  const [charts, setCharts] = useState([]);

  useEffect(() => {
    const createChart = (indicator) => {
      return {
        id: indicator.id,
        units: indicator.unitsShort,
        lastValue: indicator.lastValue,
        options: {
          title: indicator.title,
          credits: {
            enabled: false,
          },
          accessibility: {
            enabled: false,
          },
          chart: {
            backgroundColor: 'transparent',
          },
          series: [
            {
              name: indicator.seriesName,
              type: indicator.chartType,
              data: indicator.data,
            },
          ],
          xAxis: {
            type: 'category',
            labels: {
              style: {
                color: 'white',
              },
            },
          },
          yAxis: {
            labels: {
              style: {
                color: 'white',
              },
            },
            title: {
              text: `${indicator.yAxisTitle} (${indicator.units})`,
              style: {
                color: 'white',
                fontSize: '14px',
              },
            },
          },
          legend: {
            itemStyle: {
              color: 'white',
            },
          },
        },
      };
    };

    const getData = () => {
      data.forEach(async (indicator) => {
        const results = await ApiGetData.getData(indicator.code, indicator.params);
        const [metadata, data] = results.data;

        indicator.lastUpdated = new Date(metadata.lastupdated).toLocaleDateString(config.locale);

        indicator.data = data
          .reduce((result, item) => {
            const { date, value } = item;
            if (value !== null) {
              result.push([date, value]);
            }
            return result;
          }, [])
          .reverse();

        indicator.lastValue = indicator.data.at(-1);

        const chart = createChart(indicator);

        setCharts((prev) => [...prev, chart]);
      });
    };

    getData();
  }, [data]);

  return (
    <div>
      <div className='section-title'>{title.toUpperCase()}</div>
      <SectionCharts charts={charts} />
    </div>
  );
};

export default Section;
