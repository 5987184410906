import { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import constants from './constants';

Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', ' M', 'MM', 'T', 'P', 'E'],
  },
});

const SectionCharts = ({ charts }) => {
  useEffect(() => {
    Highcharts.charts.forEach((chart) => chart.reflow());
  }, [charts]);

  return (
    <div className='dashboard_charts'>
      {charts
        .sort((a, b) => a.id - b.id)
        .map((chart) => {
          const [year, value] = chart.lastValue;

          return (
            <div
              className='dashboard_chart'
              key={`${chart.seriesName}-${chart.id}`}
            >
              <div className='dashboard_chart_title'>{chart.options.title}</div>
              <div className='text-center'>{constants.lastValueTitle}</div>
              <div className='lastValue'>
                {Math.round((value + Number.EPSILON) * 100) / 100} {chart.units}
              </div>
              <div className='text-center'>({year})</div>

              <HighchartsReact
                highcharts={Highcharts}
                options={chart.options}
                globalOptions={chart.globalOptions}
              />
            </div>
          );
        })}
    </div>
  );
};

export default SectionCharts;
