import './Dashboard.css';
import constants from './constants';

import Section from './Section';

const Dashboard = () => {
  return (
    <div className='dashboard'>
      <div>
        <span>
          {constants.welcome}
          <a
            className='link'
            href={constants.sourceUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            {constants.source}
          </a>
        </span>
      </div>

      {constants.indicators.map(({ metadata, data }) => (
        <Section key={metadata.name} title={metadata.name} data={data} />
      ))}
    </div>
  );
};

export default Dashboard;
