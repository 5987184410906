const constants = {
  welcome: 'Principales indicadores de Colombia con fuente del ',
  source: 'Banco Mundial',
  sourceUrl: 'https://www.bancomundial.org/',
  lastValueTitle: 'Valor más reciente',
  indicators: [
    {
      metadata: {
        name: 'social',
      },
      data: [
        {
          id: 1,
          seriesName: 'Población Total',
          title: 'Población Total (M)',
          units: 'Millones',
          unitsShort: 'M',
          code: 'SP.POP.TOTL',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: 'Habitantes',
        },
        {
          id: 2,
          seriesName: 'Crecimiento de la población',
          title: 'Crecimiento de la población (% anual)',
          units: '%',
          unitsShort: '%',
          code: 'SP.POP.GROW',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 3,
          seriesName: 'Esperanza de vida',
          title: 'Esperanza de vida al nacer, total (años)',
          units: 'Años',
          unitsShort: 'Años',
          code: 'SP.DYN.LE00.IN',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 4,
          seriesName: 'Migración Neta',
          title: 'Migración Neta (# de personas)',
          units: 'Personas',
          unitsShort: 'Personas',
          code: 'SM.POP.NETM',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 5,
          seriesName: 'Índice de Capital Humano',
          title: 'Índice de Capital Humano (escala de 0 a 1)',
          units: 'Índice',
          unitsShort: '',
          code: 'HD.HCI.OVRL',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 6,
          seriesName: 'Tasa de pobreza menos de $1,90 por día',
          title: 'Tasa de pobreza menos de $1,90 por día',
          units: '%',
          unitsShort: '%',
          code: 'SI.POV.DDAY',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
      ],
    },

    {
      metadata: {
        name: 'educación',
      },
      data: [
        {
          id: 1,
          seriesName: 'Inscripción escolar',
          title: 'Inscripción escolar, nivel primario(%)',
          units: '%',
          unitsShort: '%',
          code: 'SE.PRM.ENRR',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 2,
          seriesName: 'Tasa de alfabetización',
          title: 'Tasa de alfabetización, adultos (% de personas > 15 años)',
          units: '%',
          unitsShort: '%',
          code: 'SE.ADT.LITR.ZS',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 3,
          seriesName: 'Tasa de finalización de la educación de nivel primario',
          title: 'Tasa de finalización nivel primario (%)',
          units: '%',
          unitsShort: '%',
          code: 'SE.PRM.CMPT.ZS',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 4,
          seriesName: 'Gasto público en educación',
          title: 'Gasto público en educación, total (%)',
          units: '%',
          unitsShort: '%',
          code: 'SE.XPD.TOTL.GB.ZS',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
      ],
    },

    {
      metadata: {
        name: 'económico',
      },
      data: [
        {
          id: 1,
          seriesName: 'PIB',
          title: 'Producto Interno Bruto (US$)',
          units: 'Mil Millones',
          unitsShort: 'MM',
          code: 'NY.GDP.MKTP.CD',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: 'US$',
        },
        {
          id: 2,
          seriesName: 'Crecimiento del PIB',
          title: 'Crecimiento del PIB (% anual)',
          units: '%',
          unitsShort: '%',
          code: 'NY.GDP.MKTP.KD.ZG',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 3,
          seriesName: 'Tasa de pobreza',
          title: 'Tasa de pobreza (% de la población)',
          units: '%',
          unitsShort: '%',
          code: 'SI.POV.NAHC',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 4,
          seriesName: 'Desempleo',
          title: 'Desempleo, total (% de la población activa total)',
          units: '%',
          unitsShort: '%',
          code: 'SL.UEM.TOTL.ZS',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 5,
          seriesName: 'Inflación',
          title: 'Inflación, precios al consumidor (% anual)',
          units: '%',
          unitsShort: '%',
          code: 'FP.CPI.TOTL.ZG',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 6,
          seriesName: 'Índice de Gini',
          title: 'Índice de Gini',
          units: 'Índice',
          unitsShort: '',
          code: 'SI.POV.GINI',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
      ],
    },
    {
      metadata: {
        name: 'medioambiente',
      },
      data: [
        {
          id: 1,
          seriesName: 'Emisiones de CO2',
          title: 'Emisiones de CO2 (toneladas per cápita)',
          units: 'Toneladas',
          unitsShort: 'Ton',
          code: 'EN.ATM.CO2E.PC',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: 'Volumen',
        },
        {
          id: 2,
          seriesName: 'Área selvática',
          title: 'Área selvática (% del área de tierra)',
          units: '%',
          unitsShort: '%',
          code: 'AG.LND.FRST.ZS',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
        {
          id: 3,
          seriesName: 'Extracción anual de agua dulce',
          title: 'Extracción anual de agua dulce (%)',
          units: '%',
          unitsShort: '%',
          code: 'ER.H2O.FWTL.ZS',
          params: { per_page: 100 },
          data: [],
          lastUpdated: '',
          lastValue: 0,
          chartType: 'line',
          yAxisTitle: '',
        },
      ],
    },
  ],
};

export default constants;
